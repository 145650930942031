@tailwind base;
@tailwind components;
@tailwind utilities;

a {
  text-decoration: none;
}

html {
  height: 100%;
}

body {
  height: 100%;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

#root {
  height: 100%;
}

img {
  display: unset !important;
}

svg {
  vertical-align: initial;
}

.ant-btn-primary {
  background: #1890ff;
}

.ant-popover-inner {
  @apply shadow-md;
}

.ant-popover {
  z-index: 1050;
}
.ant-avatar > img {
  display: block !important;
}

.bottom-menu {
}
